import * as Types from '../../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { CategoryFragmentDoc } from '../../../../graphql/fragments/category.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetChartOfAccountsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetChartOfAccountsQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, chartOfAccounts: Array<{ __typename?: 'LedgerAccount', id: string, description?: string | null, displayId: string, entityId: string, displayName: string, keywords: Array<string>, parentDisplayId?: string | null, tags: Array<string>, type: Types.LedgerAccountType, views: Array<string>, category: Types.LedgerAccountCategory, subCategory?: Types.LedgerAccountSubCategory | null, tertiaryCategory?: Types.LedgerAccountTertiaryCategory | null, companyId: string, currentness?: string | null, coaKeys: Array<string>, deprecatedCoaKeys: Array<string>, hidden: boolean, systemOwned: boolean, templateId?: string | null }> } | null };

export type CoaAccountTypesQueryVariables = Types.Exact<{
  input: Types.CoaAccountTypeQueryInput;
}>;


export type CoaAccountTypesQuery = { __typename?: 'Query', coaAccountTypes?: Array<string> | null };

export type AllCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type AllCategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename: 'LedgerCategory', defaultCashlike: Types.Cashlike, accountType?: Types.LedgerAccountType | null, views?: Array<string | null> | null, name: string, permaKey: string, examples?: string | null, description?: string | null, coaDisplayId?: string | null, coaKey: string, deprecated?: boolean | null, ruleType?: string | null, displayName: string, displayId: string, shortName: string, companyId: string, id?: string | null }> };

export type LedgerAccountFragment = { __typename?: 'LedgerAccount', id: string, description?: string | null, displayId: string, entityId: string, displayName: string, keywords: Array<string>, parentDisplayId?: string | null, tags: Array<string>, type: Types.LedgerAccountType, views: Array<string>, category: Types.LedgerAccountCategory, subCategory?: Types.LedgerAccountSubCategory | null, tertiaryCategory?: Types.LedgerAccountTertiaryCategory | null, companyId: string, currentness?: string | null, coaKeys: Array<string>, deprecatedCoaKeys: Array<string>, hidden: boolean, systemOwned: boolean, templateId?: string | null };

export const LedgerAccountFragmentDoc = gql`
    fragment ledgerAccount on LedgerAccount {
  id
  description
  displayId
  entityId
  displayName
  keywords
  parentDisplayId
  tags
  type
  views
  category
  subCategory
  tertiaryCategory
  companyId
  currentness
  coaKeys
  deprecatedCoaKeys
  hidden
  systemOwned
  templateId
}
    `;
export const GetChartOfAccountsDocument = gql`
    query getChartOfAccounts($id: ID!) {
  company(id: $id) {
    id
    chartOfAccounts {
      ...ledgerAccount
    }
  }
}
    ${LedgerAccountFragmentDoc}`;

/**
 * __useGetChartOfAccountsQuery__
 *
 * To run a query within a React component, call `useGetChartOfAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartOfAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartOfAccountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChartOfAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables> & ({ variables: GetChartOfAccountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>(GetChartOfAccountsDocument, options);
      }
export function useGetChartOfAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>(GetChartOfAccountsDocument, options);
        }
export function useGetChartOfAccountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>(GetChartOfAccountsDocument, options);
        }
export type GetChartOfAccountsQueryHookResult = ReturnType<typeof useGetChartOfAccountsQuery>;
export type GetChartOfAccountsLazyQueryHookResult = ReturnType<typeof useGetChartOfAccountsLazyQuery>;
export type GetChartOfAccountsSuspenseQueryHookResult = ReturnType<typeof useGetChartOfAccountsSuspenseQuery>;
export type GetChartOfAccountsQueryResult = Apollo.QueryResult<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>;
export const CoaAccountTypesDocument = gql`
    query coaAccountTypes($input: CoaAccountTypeQueryInput!) {
  coaAccountTypes(input: $input)
}
    `;

/**
 * __useCoaAccountTypesQuery__
 *
 * To run a query within a React component, call `useCoaAccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoaAccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoaAccountTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoaAccountTypesQuery(baseOptions: Apollo.QueryHookOptions<CoaAccountTypesQuery, CoaAccountTypesQueryVariables> & ({ variables: CoaAccountTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoaAccountTypesQuery, CoaAccountTypesQueryVariables>(CoaAccountTypesDocument, options);
      }
export function useCoaAccountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoaAccountTypesQuery, CoaAccountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoaAccountTypesQuery, CoaAccountTypesQueryVariables>(CoaAccountTypesDocument, options);
        }
export function useCoaAccountTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CoaAccountTypesQuery, CoaAccountTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CoaAccountTypesQuery, CoaAccountTypesQueryVariables>(CoaAccountTypesDocument, options);
        }
export type CoaAccountTypesQueryHookResult = ReturnType<typeof useCoaAccountTypesQuery>;
export type CoaAccountTypesLazyQueryHookResult = ReturnType<typeof useCoaAccountTypesLazyQuery>;
export type CoaAccountTypesSuspenseQueryHookResult = ReturnType<typeof useCoaAccountTypesSuspenseQuery>;
export type CoaAccountTypesQueryResult = Apollo.QueryResult<CoaAccountTypesQuery, CoaAccountTypesQueryVariables>;
export const AllCategoriesDocument = gql`
    query allCategories($companyId: ID!) {
  categories(input: {companyId: $companyId, context: HISTORICAL}) {
    ...category
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useAllCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAllCategoriesQuery(baseOptions: Apollo.QueryHookOptions<AllCategoriesQuery, AllCategoriesQueryVariables> & ({ variables: AllCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, options);
      }
export function useAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCategoriesQuery, AllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, options);
        }
export function useAllCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllCategoriesQuery, AllCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, options);
        }
export type AllCategoriesQueryHookResult = ReturnType<typeof useAllCategoriesQuery>;
export type AllCategoriesLazyQueryHookResult = ReturnType<typeof useAllCategoriesLazyQuery>;
export type AllCategoriesSuspenseQueryHookResult = ReturnType<typeof useAllCategoriesSuspenseQuery>;
export type AllCategoriesQueryResult = Apollo.QueryResult<AllCategoriesQuery, AllCategoriesQueryVariables>;