import { Add } from "@puzzle/icons";
import { Button, Stack, Text } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import React from "react";

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Content = styled(Stack, {
  width: "37.5rem",
  textAlign: "center",
  marginTop: "$7h",
});

const EmptyState = ({
  title,
  message,
  actionOnClick,
  actionName,
}: {
  title: string;
  message: string;
  actionOnClick: () => void;
  actionName: string;
}) => {
  return (
    <Container>
      <Content gap="2h">
        <Text size="headingM">{title}</Text>
        <Text size="bodyS" color="gray400">
          {message}
        </Text>
        <Button prefix={<Add />} onClick={actionOnClick} css={{ margin: "auto" }}>
          New {actionName}
        </Button>
      </Content>
    </Container>
  );
};

export default EmptyState;
