import { useCallback } from "react";
import { useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import {
  AvailableClassSegmentFragment,
  useRemoveClassFromEntityMutation,
} from "components/common/hooks/graphql.generated";
import { TaggableEntity } from "components/common/Classifications/TagEntity";
import Analytics from "lib/analytics/analytics";

export const useRemoveClassFromEntity = ({ entity }: { entity?: TaggableEntity }) => {
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [_removeClassFromEntity, { loading, reset }] = useRemoveClassFromEntityMutation();

  const showErrorToast = useCallback(() => {
    toast({
      status: "warning",
    });
  }, [toast]);

  const removeClassFromEntity = useCallback(
    async (reportingClassSegment: AvailableClassSegmentFragment) => {
      if (!entity) return;
      await _removeClassFromEntity({
        variables: {
          input: {
            companyId: company.id,
            reportingClassSegmentId: reportingClassSegment.id,
            entityId: entity.id,
            entityType: entity.type,
          },
        },
        onCompleted: () => {
          Analytics.removeClassTag({
            actionType: "UserCreated",
            entityType: entity.type,
          });
        },
        onError: () => {
          showErrorToast();
        },
      });
    },
    [_removeClassFromEntity, company.id, entity, showErrorToast]
  );

  return {
    removeClassFromEntity,
    removeClassFromEntityLoading: loading,
    reset,
  };
};
