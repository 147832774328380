import React, { useMemo, useRef, useState, useEffect } from "react";
import {
  MRT_TableOptions,
  MRT_RowData,
  MRT_TableInstance,
  MaterialReactTable,
  MRT_Row,
} from "material-react-table";
import { ThemeProvider } from "@mui/material/styles";
import { tableSpecificThemes, dataGridTheme } from "./DataGridTheme";
import { useMaterialReactTable } from "material-react-table";
import { formatMrtCol } from "./dataGridUtils";
import { RootTableStyle } from "./DataGrid.css";
import useResizeObserver from "use-resize-observer";
import { useDebouncedCallback } from "use-debounce";
import { MutableRefObject } from "react";
import { veColors as colors } from "@puzzle/theme";

export type DataGridProps<RowData extends MRT_RowData> = {
  options: MRT_TableOptions<RowData>;
  height?: string;
  tableRef?: MutableRefObject<MRT_TableInstance<RowData> | null>;
  emptyState?: React.ReactElement;
};

const defaultOptions = {
  columns: [],
  data: [],
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableSorting: false,
  enableColumnActions: false,
  enableColumnFilters: false,
  enablePagination: false,
  enableColumnVirtualization: false,
  enableStickyHeader: false,
  enableColumnOrdering: false,
};

/**
 * Latest DataTable component built off Material React Table
 * @param options - See Material React Table options
 *
 * @param height - Height of the table, defaults to 100%
 */
export const DataGrid = <RowData extends MRT_RowData>({
  options,
  height,
  tableRef,
  emptyState,
}: DataGridProps<RowData>) => {
  const col = useMemo(() => formatMrtCol(options.columns), [options.columns]);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tableContainerHeight, setTableContainerHeight] = useState(
    `${tableContainerRef.current?.clientHeight.toString()}px`
  );

  const debouncedSetTableContainerHeight = useDebouncedCallback((input) => {
    setTableContainerHeight(input);
  }, 500);

  useResizeObserver({
    ref: tableContainerRef,
    onResize: ({ height }) => {
      if (height) {
        debouncedSetTableContainerHeight(`${height}px`);
      }
    },
  });

  const table = useMaterialReactTable({
    ...defaultOptions,
    ...tableSpecificThemes,
    ...options,
    muiTableContainerProps: {
      sx: { maxHeight: height || tableContainerHeight },
      ...options.muiTableContainerProps, // overrides that are passed down
    },

    muiTableHeadCellProps: {
      // This gives all tables the style where action icons are hidden until hovered over
      // Except for the "Select All" checkbox in the header
      sx: (theme) => ({
        ".Mui-TableHeadCell-Content": {
          // default color of header text
          color: theme.palette.text.secondary,
          // hiding the following icons via color still allows the icons to be read by screen readers

          // hide the SORT icons until the header is hovered over
          "svg.MuiTableSortLabel-icon": {
            color: theme.palette.background.default,
            fill: theme.palette.background.default,
          },
          // hide the DRAGGABLE icons until the header is hovered over
          "button[draggable] svg": {
            color: theme.palette.background.default,
            fill: theme.palette.background.default,
          },
          // hide the MENU icons (like FILTER) until the header is hovered over
          "button[role=menu] svg": {
            color: theme.palette.background.default,
            fill: theme.palette.background.default,
          },

          "&:hover": {
            // On hover, highlight the text
            ".Mui-TableHeadCell-Content-Wrapper": {
              color: theme.palette.text.primary,
            },
            // On hover, highlight the SORT icons
            "svg.MuiTableSortLabel-icon": {
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
            },
            // On hover, highlight the DRAGGABLE icons
            "button[draggable] svg": {
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
            },
            // On hover, highlight the MENU icons (like FILTER)
            "button[role=menu] svg": {
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
            },
          },
        },
      }),
      ...options.muiTableHeadCellProps, // overrides that are passed down
    },
    // muiTableRowProps and other MRT properties may be simple objects that can be passed down
    // or they may be functions that take in the row and return an object
    // So we need to check if it's a function or not and handle accordingly
    muiTableBodyRowProps: ({
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      isDetailPanel,
      row,
      staticRowIndex,
      table,
    }: {
      isDetailPanel?: boolean;
      row: MRT_Row<RowData>;
      staticRowIndex: number;
      table: MRT_TableInstance<RowData>;
    }) => {
      const props =
        typeof options.muiTableBodyRowProps === "function"
          ? options.muiTableBodyRowProps({ row, staticRowIndex, table })
          : options.muiTableBodyRowProps;
      return {
        ...props, // Overrides that are passed down, except for the "sx" property (handled below)
        sx: {
          // MRT adds an :after pseudo element to each cell to tint the background color
          // We want to remove this and allow for styling how we want
          "td:after": {
            backgroundColor: `transparent`,
          },
          backgroundColor: row.getIsSelected() ? `${colors.mauve900} !important` : "inherit",
          "&:hover": {
            "td:after": {
              backgroundColor: `transparent`,
            },
            backgroundColor: `${colors.porpoise300} !important`,
          },
          ...props?.sx, // This overrides any styles that are also listed above. Passed from from the specific table options.
        },
      };
    },

    columns: col,
    renderEmptyRowsFallback: () =>
      emptyState ?? <div style={{ padding: "10px" }}>No results found.</div>,
  });

  useEffect(() => {
    if (tableRef) {
      tableRef.current = table;
    }
  }, [table, tableRef]);

  return (
    <div ref={tableContainerRef} className={RootTableStyle}>
      <ThemeProvider theme={dataGridTheme}>
        <MaterialReactTable table={table} />
      </ThemeProvider>
    </div>
  );
};
