import React, { Fragment, useState } from "react";

import { Add, Check, Clear, Close, External, Search } from "@puzzle/icons";
import { Button, Input, Menu, Stack, Tag } from "@puzzle/ui";
import { colors } from "@puzzle/theme";
import { Text, Loader } from "ve";
import { Box } from "@puzzle/ve";
import { veColors as color, S } from "@puzzle/theme";
import { zIndex } from "@puzzle/utils";

import { useClassifications } from "components/common/hooks/useClassifications";
import { useAddClassToEntity } from "components/common/hooks/useAddClassToEntity";
import { ClassAwareType, ReportingClassType } from "graphql/types";
import {
  AvailableClassSegmentFragment,
  AvailableReportingClassFragment,
} from "../hooks/graphql.generated";
import { useRemoveClassFromEntity } from "../hooks/useRemoveClassFromEntity";
import { Link } from "../Link";
import { Route } from "lib/routes";
import { ShowModalTypes } from "components/settings/Classifications/Classes/Classes";
import { CreateClassificationModal } from "./CreateClassificationModal";

import { altStyle, smallAddButton } from "./tagEntity.css";

export type ClassSegment = {
  id: string;
  name: string;
  reportingClass: { id: string; name: string; type: ReportingClassType };
};

export type TaggableEntity = {
  id: string;
  name: string;
  type: ClassAwareType;
  classSegments: ClassSegment[];
};

export const TagEntity = ({
  entity,
  hideClassIds,
  activeSegment,
  readOnly = false,
  tagStyle = "default",
  createButtonStyle = "default",
  createButtonProps,
  createButtonIcon,
  createButton,
  onSegmentChange,
  classSegments = [],
}: {
  entity?: TaggableEntity;
  hideClassIds?: string[];
  activeSegment?: any; // todo: fix
  readOnly?: boolean;
  tagStyle?: "default" | "jigsaw2";
  createButtonStyle?: "textless" | "default" | "smallAdd" | "classifications";
  createButtonIcon?: React.JSX.Element;
  createButton?: React.JSX.Element;
  createButtonProps?: React.ComponentProps<typeof Button>;
  onSegmentChange?: (segments: ClassSegment[]) => void;
  classSegments?: ClassSegment[];
}) => {
  const { sortedClassifications, classificationsLoading } = useClassifications({});
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<ShowModalTypes | null>(null);
  const [editingReportingClass, setEditingReportingClass] =
    useState<AvailableReportingClassFragment | null>(null);
  const { applyClassToEntity, applyClassToEntityLoading } = useAddClassToEntity({
    entity,
  });
  const { removeClassFromEntity, removeClassFromEntityLoading } = useRemoveClassFromEntity({
    entity,
  });

  let displayClasses = sortedClassifications || [];

  // if there is a list of classes to hide, remove those
  // for example, if there are already tags associated with a transaction we will exclude
  if (hideClassIds) {
    displayClasses = displayClasses.filter(
      (reportingClass) => !hideClassIds.includes(reportingClass.id)
    );
  }

  // if there is a selected segment provided
  // this UI will to show the pill for that segment and
  // we will filter to only show values within the same class as that segment
  if (activeSegment) {
    displayClasses = displayClasses.filter(
      (reportingClass) => reportingClass.id === activeSegment.reportingClass.id
    );
  }

  if (!displayClasses.length) {
    return (
      <Link
        css={{ display: "flex", alignItems: "center" }}
        href={Route.classesSettings}
        target="_blank"
        rel="noreferrer noopener"
      >
        View and add classifications <External css={{ marginLeft: "$0h" }} />
      </Link>
    );
  }

  if (classificationsLoading && !sortedClassifications) {
    return null;
  }

  const handleSegmentAdd = (
    classSegment: AvailableClassSegmentFragment,
    reportingClass: AvailableReportingClassFragment
  ) => {
    if (entity) {
      applyClassToEntity(classSegment);
    } else {
      const segment = {
        id: classSegment.id,
        name: classSegment.name,
        reportingClass: {
          id: reportingClass.id,
          name: reportingClass.name,
          type: reportingClass.type,
        },
      };
      onSegmentChange && onSegmentChange([...classSegments, segment]);
    }
  };

  const handleSegmentRemove = (segment: AvailableClassSegmentFragment) => {
    if (entity) {
      removeClassFromEntity(segment);
    } else {
      const newSegments = classSegments.filter((s) => s.id !== segment.id);
      onSegmentChange && onSegmentChange(newSegments);
    }
  };

  // if there is a search term (more than 1 char), filter classes
  // for class names that match the search term or classes that have a
  // segment that matches the search term
  if (searchTerm.length > 1) {
    displayClasses = displayClasses.filter((reportingClass) => {
      if (reportingClass.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
      return reportingClass.availableValues.some((v) => {
        return v.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
  }

  // if we are adding a segment to an entity, show loading indicator
  if (removeClassFromEntityLoading || applyClassToEntityLoading) {
    return <Loader css={{ justifyContent: "start", marginLeft: S["1"] }} />;
  }

  if (readOnly && activeSegment) {
    return (
      <Tag size="medium" key={activeSegment.id}>
        <span>{activeSegment.name}</span>
      </Tag>
    );
  }

  const addIcon = createButtonIcon ?? <Add size={14} />;
  const addText = createButtonStyle === "classifications" ? "Classifications" : "Add";

  const getTriggerButton = () => {
    if (activeSegment) {
      // in selected segment mode, we just show the tag
      // we can't actually use the onRemove of this because the remove doesn't trigger
      // as we are in the menu trigger which takes presendence
      // instead the clear icon is placed over this using negative left position
      return (
        <Tag
          size="medium"
          key={activeSegment.id}
          className={tagStyle === "jigsaw2" ? altStyle : undefined}
        >
          {tagStyle === "jigsaw2" ? (
            <>
              <Text variant="bodyXS" color="gray300">
                {activeSegment.reportingClass.name}
              </Text>
              <Text variant="bodyXS" color="white">
                {activeSegment.name}
              </Text>
              <span style={{ width: "16px" }}></span>
            </>
          ) : (
            <>
              <span>{activeSegment.name}</span>
              <span style={{ width: "16px" }}></span>
            </>
          )}
        </Tag>
      );
    }
    if (createButton) {
      return createButton;
    }
    if (createButtonStyle === "smallAdd") {
      return (
        <div className={smallAddButton}>
          <Add color="rgba(255, 255, 255, 0.8)" size={12} />
        </div>
      );
    }
    return (
      // in non selected segement mode, show the add button
      <Button
        data-testid="add-classification-button"
        key="add-btn"
        variant="minimal"
        size="compact"
        prefix={createButtonStyle !== "textless" ? addIcon : undefined}
        css={createButtonStyle === "textless" ? { width: "100%" } : undefined}
        {...createButtonProps}
      >
        {createButtonStyle === "textless" ? addIcon : addText}
      </Button>
    );
  };

  return (
    <>
      <Stack direction="horizontal" gap="0">
        <Menu
          onOpenChange={() => setSearchTerm("")}
          trigger={getTriggerButton()}
          css={{
            minWidth: "190px",
            backgroundColor: colors.rhino700,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: colors.rhino600,
            zIndex: zIndex("modal"),
          }}
        >
          <Box
            css={{ padding: "4px" }}
            onKeyDown={(e) => {
              // prevents refocus when typing first letter
              // which matches first letter of a menu item
              e.stopPropagation();
            }}
          >
            <Input
              type="text"
              size="compact"
              prefix={<Search />}
              suffix={searchTerm ? <Clear onClick={() => setSearchTerm("")} /> : null}
              css={{ backgroundColor: colors.mauve800, width: "180px" }}
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </Box>
          {!displayClasses.length && (
            // if there are no classes, show no results
            <Menu.Group css={{ minWidth: "180px" }}>
              <Menu.Item disabled={true}>No results...</Menu.Item>
            </Menu.Group>
          )}
          {!!displayClasses.length &&
            (searchTerm.length < 2 && !activeSegment ? (
              // if there isn't a search term and there is no selected segment
              // show the nested view
              <Menu.Group css={{ backgroundColor: colors.rhino700 }}>
                {displayClasses.map((reportingClass) => {
                  return (
                    <Menu
                      key={reportingClass.id}
                      css={{
                        minWidth: "180px",
                        backgroundColor: colors.rhino700,
                        zIndex: zIndex("modal"),
                      }}
                      subMenuTriggerProps={{
                        css: {
                          "&:hover, &:focus, &:active": {
                            backgroundColor: `${colors.rhino600} !important`,
                          },
                        },
                      }}
                      subMenuTrigger={
                        <Box
                          css={{
                            paddingTop: "2px",
                            width: "100%",
                            paddingBottom: "2px",
                          }}
                        >
                          {reportingClass.name}
                        </Box>
                      }
                    >
                      <Menu.Group
                        css={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: colors.rhino600,
                        }}
                      >
                        {reportingClass.availableValues.map((reportingClassSegment) => (
                          <Menu.Item
                            key={reportingClassSegment.id}
                            css={{
                              backgroundColor: colors.rhino700,
                              "&:hover, &:focus, &:active": {
                                backgroundColor: `${colors.rhino600} !important`,
                              },
                            }}
                            onClick={() => {
                              handleSegmentAdd(reportingClassSegment, reportingClass);
                            }}
                          >
                            <Box css={{ paddingTop: "2px", paddingBottom: "2px" }}>
                              {reportingClassSegment.name}
                            </Box>
                          </Menu.Item>
                        ))}
                        <Menu.Item
                          css={{ fontWeight: "$bold" }}
                          onClick={() => {
                            setEditingReportingClass(reportingClass);
                            setShowModal(ShowModalTypes.CreateClass);
                          }}
                        >
                          <Stack gap="0h" direction="horizontal">
                            <Add />
                            <span>New class</span>
                          </Stack>
                        </Menu.Item>
                      </Menu.Group>
                    </Menu>
                  );
                })}
                <Menu.Item
                  css={{ fontWeight: "$bold" }}
                  onClick={() => setShowModal(ShowModalTypes.CreateGroup)}
                >
                  <Stack gap="0h" direction="horizontal">
                    <Add />
                    <span>New group</span>
                  </Stack>
                </Menu.Item>
              </Menu.Group>
            ) : (
              // otherwise show the unnested view
              <Menu.Group
                css={{
                  minWidth: "180px",
                }}
              >
                {displayClasses.map((reportingClass) => {
                  // if the items are appearing because of the class name, include all possible values
                  // if the items are appearing because of the specific segment value, remove non matching segment values
                  const valuesToShow = reportingClass.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                    ? reportingClass.availableValues
                    : reportingClass.availableValues.filter((v) =>
                        v.name.toLowerCase().includes(searchTerm.toLowerCase())
                      );
                  return (
                    <Fragment key={reportingClass.id}>
                      {!activeSegment && (
                        // in selected segment mode we don't need to show the class item
                        <Menu.Item
                          disabled={true}
                          css={{
                            backgroundColor: colors.rhino750,
                            color: "red",
                          }}
                        >
                          <Box
                            css={{
                              color: color.gray500,
                              paddingTop: "2px",
                              paddingBottom: "2px",
                            }}
                          >
                            {reportingClass.name}
                          </Box>
                        </Menu.Item>
                      )}
                      {valuesToShow.map((reportingClassSegment) => {
                        const isCurrentSelection =
                          activeSegment && activeSegment.id === reportingClassSegment.id;
                        return (
                          <Menu.Item
                            key={reportingClassSegment.id}
                            css={{
                              color: isCurrentSelection
                                ? `${colors.gray200} !important`
                                : undefined,
                              backgroundColor: isCurrentSelection
                                ? colors.rhino600
                                : colors.rhino700,
                              "&:hover, &:focus, &:active": {
                                backgroundColor: `${colors.rhino600} !important`,
                              },
                            }}
                            disabled={isCurrentSelection}
                            onClick={() => {
                              handleSegmentAdd(reportingClassSegment, reportingClass);
                            }}
                          >
                            <Stack
                              direction="horizontal"
                              css={{ justifyContent: "space-between", width: "100%" }}
                            >
                              <Box
                                css={{
                                  paddingLeft: !activeSegment ? "10px" : undefined,
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                }}
                              >
                                {reportingClassSegment.name}
                              </Box>
                              {isCurrentSelection && <Check />}
                            </Stack>
                          </Menu.Item>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Menu.Group>
            ))}
        </Menu>
        {activeSegment && (
          <Close
            size={12}
            css={{ position: "relative", left: "-24px" }}
            onClick={() => handleSegmentRemove(activeSegment)}
          />
        )}
      </Stack>
      <CreateClassificationModal
        open={showModal !== null || !!editingReportingClass}
        onOpenChange={(_open: boolean) => {
          setEditingReportingClass(null);
          setShowModal(null);
        }}
        reportingClassType={ReportingClassType.UserCreated}
        reportingClass={editingReportingClass}
        classifications={displayClasses}
        allowReportingClassSelection={showModal === ShowModalTypes.CreateClass}
        allReportingClasses={displayClasses}
      />
    </>
  );
};
