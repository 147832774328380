import React from "react";
import { LockOutline } from "@puzzle/icons";

import { veColors as colors } from "@puzzle/theme";

import { rootStyle } from "./lockedSelectPlaceholder.css";

export const LockedSelectPlaceholder = ({
  title,
  fullWidth = false,
}: {
  title: string;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={rootStyle}
      style={{
        width: fullWidth ? "100%" : "auto",
      }}
    >
      {title}
      <LockOutline size={16} color={colors.white50} />
    </div>
  );
};
